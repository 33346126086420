import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css";

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { Amplify } from 'aws-amplify'

import amplifyconfig from './amplifyconfiguration.json'

Amplify.configure(amplifyconfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

