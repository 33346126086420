import React, { useEffect, useState } from "react";
import ListGroup from "../components/ListGroup";
import { Alert } from "../components/Alert";
import Button from "../components/Button";

import "./OldApp.css";

const OldApp = () => {
    const [currentTime, setCurrentTime] = useState(0);
    const [currentHello, setCurrentHello] = useState(1);

    const [alertVisible, setAlertVisible] = useState(false);

    let items = ["Toronto", "Vancouver", "Hamilton", "Tokyo"];

    useEffect(() => {
        fetch("/skills")
            .then((res) => res.json())
            .then((data) => {
                setCurrentTime(data.skills);
            });
    }, []);

    useEffect(() => {
        fetch("/skills")
            .then((res) => console.log(res))
            .then((data) => {
                console.log(data)
                setCurrentHello(data.skills);
            });
    }, []);

    const handleOnSelectItem = (item) => {
        console.log(item);
    };

    return (
        <div className="App">
            <ListGroup
                items={items}
                heading="Cities"
                onSelectItem={handleOnSelectItem}
            ></ListGroup>

            <br></br>
            {alertVisible && (
                <Alert onDismiss={() => setAlertVisible(false)}>
                    Something went wrong
                </Alert>
            )}

            <Button color="danger" onClick={() => setAlertVisible(true)}>
                <h1>HELLO</h1>
            </Button>

            <br></br>
            <br></br>

            <p>The current time is {currentTime}.</p>
            <p>The current hello is {currentHello}.</p>

        </div>
    );
};

export default OldApp;
