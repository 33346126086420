import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { motion } from "framer-motion";

import "./Navbar.css";

import React, { useEffect, useState } from "react";
// Import useLocation hook
import { Link, useLocation } from "react-router-dom";

function NavigationBar() {
  const [expanded, setExpanded] = useState(false);

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  return (
    <Navbar
      expand="lg"
      className="CustomNavbar bg-body-tertiary"
      bg="dark"
      data-bs-theme="dark"
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand className="CustomBrand" href="/">
          <Nav.Link as={Link} to={"/"} onClick={() => setExpanded(false)}>
            <img
              className="LogoImage"
              src={require("../assets/site-logo.png")}
              alt="Jamie"
            />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="mr-auto"
          expanded={expanded}
        >
          <Nav className="ms-auto">
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.3 }}>
              <Nav.Link
                onClick={() => setExpanded(!expanded)}
                className={
                  "CustomNavLink" + (url === "/about" ? " CustomActive" : "")
                }
                as={Link}
                to={"/about"}
              >
                About
              </Nav.Link>
            </motion.div>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.3 }}>
              <Nav.Link
                onClick={() => setExpanded(!expanded)}
                className={
                  "CustomNavLink" + (url === "/resume" ? " CustomActive" : "")
                }
                as={Link}
                to={"/resume"}
              >
                Resume
              </Nav.Link>
            </motion.div>
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.3 }}>
              <Nav.Link
                onClick={() => setExpanded(!expanded)}
                className={
                  "CustomNavLink" + (url === "/contact" ? " CustomActive" : "")
                }
                as={Link}
                to={"/contact"}
              >
                Contact
              </Nav.Link>
            </motion.div>

            <NavDropdown title="Fun Stuff" align="end" animation={true}>
              <NavDropdown.Item>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 1.3 }}
                >
                  <Nav.Link
                    onClick={() => setExpanded(!expanded)}
                    as={Link}
                    to={"/prettifier"}
                    className={
                      "" + (url === "/prettifier" ? " CustomActive" : "")
                    }
                  >
                    JSON Prettifier
                  </Nav.Link>
                </motion.div>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 1.3 }}
                >
                  <Nav.Link
                    onClick={() => setExpanded(!expanded)}
                    as={Link}
                    to={"/jwtdecoder"}
                    className={
                      "" + (url === "/jwtdecoder" ? " CustomActive" : "")
                    }
                  >
                    JWT Decoder
                  </Nav.Link>
                </motion.div>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 1.3 }}
                >
                  <Nav.Link
                    onClick={() => setExpanded(!expanded)}
                    as={Link}
                    to={"/randstringgenerator"}
                    className={
                      "" +
                      (url === "/randstringgenerator" ? " CustomActive" : "")
                    }
                  >
                    Random String Generator
                  </Nav.Link>
                </motion.div>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
