import { useEffect, useState } from "react";
import "./Skills.css";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";

import { motion } from "framer-motion";

import { get } from "aws-amplify/api";

async function getSkills() {
  try {
    const restOperation = get({
      apiName: "PortfolioSiteAPI",
      path: "/api/skills",
    });

    const { body } = await restOperation.response;
    const response = await body.json();
    console.log(response);
    return response;
  } catch (e) {
    console.log("GET call failed: ", JSON.parse(e.response.body));
  }
}

const Skills = () => {
  const [currentSkills, setCurrentSkills] = useState([]);

  useEffect(() => {
    getSkills().then((data) => {
      setCurrentSkills(data.data);
    });
  }, []);

  return (
    <>
      {currentSkills.length === 0 && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <div className=" SkillContainer">
        {currentSkills.map((item, index) => (
          <OverlayTrigger
            delay={{ show: 0, hide: 0 }}
            placement="bottom-end"
            overlay={
              <Tooltip id="button-tooltip-2">{item.description.S}</Tooltip>
            }
          >
            <div key={item.id.S} className="Skill">
              <motion.div whileHover={{ scale: 1.1 }}>
                {item.name.S}
              </motion.div>
            </div>
          </OverlayTrigger>
        ))}
      </div>
    </>
  );
};

export default Skills;
