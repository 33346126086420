import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";

import JSONPretty from "react-json-pretty";
import { motion } from "framer-motion";

import Transition from "../transitions/transition";
import "./TokenDecoder.css";

function TokenDecoder() {
  const [name, setName] = useState("");

  const copyHeaderToClipboard = () =>
    navigator.clipboard.writeText(JSON.stringify(parseJwt(name, 0), null, 2));
  const copyPayloadToClipboard = () =>
    navigator.clipboard.writeText(JSON.stringify(parseJwt(name, 1), null, 2));

  const clearInput = () => {
    setName("");
  };

  const parseJwt = (token, seg) => {
    try {
      return JSON.parse(atob(token.split(".")[seg]));
    } catch (e) {
      return null;
    }
  };

  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <h3>Token to decode</h3>
          <Form.Control
            type="email"
            as="textarea"
            className="InputArea"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder=""
          />
        </Form.Group>
      </Form>
      <h3>Decoded Token</h3>
      <div className="OutputArea">
        <h5>Header</h5>
        <JSONPretty
          className="OutputBox"
          id="json-pretty"
          space="4"
          data={JSON.stringify(parseJwt(name, 0))}
        ></JSONPretty>
        <br />
        <h5>Payload</h5>
        <JSONPretty
          className="OutputBox"
          id="json-pretty"
          space="4"
          data={JSON.stringify(parseJwt(name, 1))}
        ></JSONPretty>
      </div>
      <motion.div
        className="ButtonHolder"
        whileHover={{ scale: 1.1}}
        whileTap={{ scale: 1.3 }}
      >
        <Button className="btn CustomPrimary" onClick={copyHeaderToClipboard}>
          <i class="bi bi-clipboard"></i> Copy header to clipboard
        </Button>
        <br />
      </motion.div>
      <motion.div
        className="ButtonHolder"
        whileHover={{ scale: 1.1}}
        whileTap={{ scale: 1.3 }}
      >
        <Button className="btn CustomPrimary" onClick={copyPayloadToClipboard}>
          <i class="bi bi-clipboard"></i> Copy payload to clipboard
        </Button>
        <br />
      </motion.div>
      <motion.div
        className="ButtonHolder"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 1.3 }}
      >
        <Button className="btn CustomDanger" onClick={clearInput}>
          <i class="bi bi-trash3"></i> Clear Input
        </Button>
      </motion.div>
    </>
  );
}

export default Transition(TokenDecoder);
