import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import About from "../routes/About";
import Contact from "../routes/Contact";
import OldApp from "../routes/OldApp";
import NotFound from "../routes/ErrorPages/NotFound";
import SomethingWrong from "../routes/ErrorPages/SomethingWrong";
import Home from "../routes/Home";
import Resume from "../routes/Resume";
import Prettifier from "../routes/Prettifier";
import TokenDecoder from "../routes/TokenDecoder";
import RandomStringGenerator from "../routes/RandomStringGenerator";
import AdminConsole from "../routes/AdminConsole";

const AnimatedRoutes = () => {

    const location = useLocation()

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          exact
          path="/"
          element={<Home />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/debug"
          element={<OldApp />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/about"
          element={<About />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/resume"
          element={<Resume />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/contact"
          element={<Contact />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/prettifier"
          element={<Prettifier />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/jwtdecoder"
          element={<TokenDecoder />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/randstringgenerator"
          element={<RandomStringGenerator />}
          errorElement={<SomethingWrong />}
        />
        <Route
          exact
          path="/admin"
          element={<AdminConsole />}
          errorElement={<SomethingWrong />}
        />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
