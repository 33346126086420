import Transition from "../transitions/transition";

import "./Contact.css"


const Contact = () => {
    return (
        <>
            <h1>Contact Me!</h1>
            <div className="ContactCard">
                <i className="bi bi-phone"></i> 647-968-8603
            </div>
            <div className="ContactCard">
                <i className="bi bi-envelope"></i> jsommerville99@gmail.com
            </div>
            <div className="ContactCard">
                <a href="https://www.linkedin.com/in/jamie-sommerville-02049a238/">
                    <i className="bi bi-linkedin"></i> Jamie Sommerville
                </a>
            </div>
            <div className="ContactCard">
                <a href="https://github.com/JamesSommerville1">
                    <i className="bi bi-github"></i> JamesSommerville1
                </a>
            </div>
        </>
    );
};

export default Transition(Contact);
