import "./styles/App.css";

import { BrowserRouter as Router } from "react-router-dom";

import AnimatedRoutes from "./components/AnimatedRoutes";
import NavigationBar from "./components/Navbar";

function App() {
  return (
    <Router>
      <NavigationBar></NavigationBar>
      <div className="PageContent">
        <AnimatedRoutes></AnimatedRoutes>
      </div>
    </Router>
  );
}

export default App;
