import { ReactNode } from "react";

interface Props {
    children: ReactNode;
    onDismiss: () => void;
}

export const Alert = ({ children, onDismiss }: Props) => {
    return (
        <>
            <div
                className="alert alert-warning alert-dismissible fade show"
                role="alert"
            >
                <strong>Alert!</strong> {children}{" "}
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={onDismiss}
                ></button>
            </div>
        </>
    );
};
