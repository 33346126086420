import { useState } from "react";
import Transition from "../transitions/transition";
import "./RandomStringGenerator.css";
import { get, post } from "aws-amplify/api";
import { motion } from "framer-motion";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

function RandomStringGenerator() {
  const [oneChecked, setOneChecked] = useState(1);
  const [numString, setNumString] = useState(8);
  const [numChar, setNumChar] = useState(8);
  const [randomStrings, setRandomStrings] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onSelectItem = (item) => {
    navigator.clipboard.writeText(item);
  };

  const handleChange = (event) => {
    if (event.target.checked) {
      setOneChecked(oneChecked + 1);
    } else {
      setOneChecked(oneChecked - 1);
    }
  };

  const handleNumStringChange = (event) => {
    console.log(event.target.value);
    setNumString(event.target.value);
  };

  const handleNumCharChange = (event) => {
    setNumChar(event.target.value);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click item to copy to clipboard
    </Tooltip>
  );

  async function generateRandomStrings(json_body) {
    try {
      const restOperation = post({
        apiName: "PortfolioSiteAPI",
        path: "/api/rsg",
        options: {
          headers: {
            "Content-Type": "application/json",
          },
          body: json_body,
        },
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      console.log(response);
      return response;
    } catch (e) {
      console.log("GET call failed: ", JSON.parse(e.response.body));
    }
  }

  const handleSubmit = (event) => {
    if (oneChecked <= 0 || numString < 1 || numChar < 1) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    event.preventDefault();

    let jsonbody = {
      numChars: parseInt(event.target[0].value),
      numStrings: parseInt(event.target[1].value),
      alphaUpper: event.target[2].checked,
      alphaLower: event.target[3].checked,
      numericChars: event.target[4].checked,
      specialChars: event.target[5].checked,
    };

    generateRandomStrings(jsonbody).then((res) => setRandomStrings(res));
  };

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="formChars">
          <Form.Label column sm={3}>
            Number of characters
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              onChange={handleNumCharChange}
              type="number"
              isValid={numChar >= 1}
              isInvalid={numChar < 1}
              placeholder="8"
              defaultValue="8"
              required
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="formNumber">
          <Form.Label column sm={3}>
            Number of strings to generate
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              onChange={handleNumStringChange}
              type="number"
              isValid={numString >= 1}
              isInvalid={numString < 1}
              placeholder="8"
              defaultValue="8"
              required
            />
          </Col>
        </Form.Group>

        <Form.Group className="mb-3">
          <Row>
            <Form.Label as="legend" column sm={3}>
              Characters
            </Form.Label>
            <Col sm={9} className="CheckboxCol">
              <Form.Check
                type="Checkbox"
                onChange={handleChange}
                isInvalid={oneChecked <= 0}
                isValid={oneChecked > 0}
                label="A-Z"
                name="formHorizontalCheckbox"
                id="formAlphaUpper"
                defaultChecked
              />
              <Form.Check
                type="Checkbox"
                onChange={handleChange}
                isInvalid={oneChecked <= 0}
                isValid={oneChecked > 0}
                label="a-z"
                name="formHorizontalCheckbox"
                id="formAlphaLower"
              />
              <Form.Check
                type="Checkbox"
                onChange={handleChange}
                isInvalid={oneChecked <= 0}
                isValid={oneChecked > 0}
                label="0-9"
                name="formHorizontalCheckbox"
                id="formNumerical"
              />
              <Form.Check
                type="Checkbox"
                isInvalid={oneChecked <= 0}
                isValid={oneChecked > 0}
                onChange={handleChange}
                label="Special Characters"
                name="formHorizontalCheckbox"
                id="formSpecial"
              />
              {oneChecked <= 0 && (
                <div className="RedText">Check At Least one</div>
              )}
            </Col>
          </Row>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Col sm={{ span: 10, offset: 1 }}>
            <motion.div
              className="ButtonHolder"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.3 }}
            >
              <Button className="CustomPrimary" type="submit">
                <i class="bi bi-arrow-clockwise"></i> Generate Random Strings
              </Button>
            </motion.div>
          </Col>
        </Form.Group>
      </Form>
      <br />
      {randomStrings.length === 0 && <p> No Items Found</p>}
      <OverlayTrigger
        placement="top"
        delay={{ show: 100, hide: 100 }}
        overlay={renderTooltip}
      >
        <ul className="list-group">
          {randomStrings.map((item, index) => (
            <li
              key={item}
              className={
                selectedIndex === index
                  ? "list-group-item active"
                  : "list-group-item"
              }
              onClick={() => {
                setSelectedIndex(index);
                onSelectItem(item);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      </OverlayTrigger>
    </>
  );
}

export default Transition(RandomStringGenerator);
