import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skills from "../components/Skills";

import Transition from "../transitions/transition";
import { motion } from "framer-motion";

import "./Resume.css";

const Resume = () => {
  return (
    <Container className="ResumeContainer" fluid={true}>
      <Row>
        <Col className="ColumnTextLeft">
          <h2>Profile</h2>
          <p>
            I'm a skilled Web Developer with extensive experience in backend
            services, primarily focusing on Python programming and hosting on
            AWS cloud computing services. I thrive on designing and implementing
            API services using AWS Lambda framework for large-scale initiatives.
            What sets me apart is my strong work ethic and a knack for quickly
            grasping new technologies. I've played a vital role in various cloud
            migration projects, including the development of user migration
            systems backed by relational databases. I'm a firm believer in
            strong testing practices and clean coding principles, ensuring that
            my code is of the highest quality and maintainability standards. In
            summary, my background in backend services, cloud computing,
            technical leadership, and SQL proficiency positions me as a valuable
            asset for web development projects.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="ColumnTextLeft">
          <h2>Experience</h2>
          <div className="ExperienceHeading">
            <div className="InlineTitle LargeText">Developer</div>
            <div className="InlineHeading MedText">Altus Group</div>
            <div>June 2022 - Feb 2024</div>
          </div>
          <ul className="ExperienceList">
            <li>
              Experience on multiple teams with a focus on backend services
              written in python and hosted on AWS and Azure cloud computing
              services.
            </li>
            <li>
              Worked on API services based on AWS Lambda framework to support
              large company initiatives to consolidate legacy applications into
              a centralized framework (Altus Performance Platform).
            </li>
            <li>
              Played a pivotal role in designing and implementing an AWS Step
              Function based user migration system, facilitating the migration
              of over 15,000 users' data into the Altus Performance Platform,
              backed by relational database PostgreSQL running in Amazon RDS.
            </li>
            <li>
              Maintained strong testing practices, achieving 80% or greater test
              coverage, and adhering to clean coding principles as per the
              required standards (e.g., Flake8).
            </li>
            <li>
              Conducted 10+ technical interviews for senior developer positions,
              demonstrating proficiency in analyzing solutions, providing
              insights, and formulating unique questions for the technical
              interview process. Additionally, provided leadership in the
              technical interview process, guiding team leaders in evaluating
              candidates, and ensuring a rigorous and fair assessment process.
            </li>
          </ul>
          <div className="ExperienceHeading">
            <div className="InlineTitle LargeText">
              Junior Full Stack Developer
            </div>
            <div className="InlineHeading MedText">Altus Group</div>
            <div>Summer 2019,2020,2021</div>
          </div>
          <ul className="ExperienceList">
            <li>
              Collaborated with team members to deliver full front and back end
              code, maintenance, and testing for multiple web applications,
              including report delivery, online file management solutions, and
              unclaimed property management portal.
            </li>
            <li>
              Played a key role in incident mitigation by facilitating data
              recovery and organization for multiple business units using cloud
              storage solutions.
            </li>
            <li>
              Developed REST API frameworks and endpoints using Node.js to
              support application functionalities.
            </li>
            <li>
              Implemented clean and functional UI and UX designs using HTML and
              CSS, ensuring a seamless user experience.
            </li>
          </ul>
        </Col>
      </Row>
      <Row xs={1} sm={1} md={2} lg={3}>
        <Col className="ColumnTextLeft">
          <h2>Education</h2>
          <p className="Certification">
            <strong>Bachelor of Science </strong>: Computer Science with Minor
            in Geography<br></br>
            <i>Wilfrid Laurier University</i>
          </p>
        </Col>
        <Col className="ColumnTextLeft">
          <h1>Certifications</h1>
          <p className="Certification">AWS Certified Associate Developer (In progress)</p>
        </Col>
        <Col className="ColumnTextLeft">
          <h1>Skills</h1>
          <Skills></Skills>
        </Col>
      </Row>

      <Row xs={1}>
        <a
          href={require("../assets/Jamie Sommerville Resume Latest.pdf")}
          download="JamieSommervilleResume"
        >
          <motion.div whileHover={{ scale: 1.1 }}>
            <div className="DownloadResumeButton">Download</div>
          </motion.div>
        </a>
      </Row>
    </Container>
  );
};

export default Transition(Resume);
