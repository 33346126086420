import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { signOut, getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Container from "react-bootstrap/Container";

import { get, post, del } from "aws-amplify/api";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "./AdminConsole.css";

function AdminConsole() {
  useEffect(() => {
    checkUser();
  }, []);
  const [user, setUser] = useState({});

  const [currentSkills, setCurrentSkills] = useState([]);

  useEffect(() => {
    getSkills().then((data) => {
      setCurrentSkills(data.data);
    });
  }, []);

  async function currentAuthenticatedUser() {
    try {
      const data = await getCurrentUser();
      console.log(data);
      return data;
    } catch (err) {
      console.log(err);
    }
  }

  async function checkUser() {
    try {
      const data = await currentAuthenticatedUser();
      const userInfo = { email: data.signInDetails.loginId };
      setUser(userInfo);
    } catch (err) {
      console.log("error: ", err);
    }
  }

  function signOutUser() {
    signOut().catch((err) => console.log("error signing out: ", err));
  }

  async function createSkill(json_body) {
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

    console.log(authToken);

    try {
      const restOperation = post({
        apiName: "PortfolioSiteAPI",
        path: "/api/skills",
        options: {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
          body: json_body,
        },
      });

      const { body } = await restOperation.response;
      console.log(body);
      const response = await body.json();
      console.log(response);
      return response;
    } catch (e) {
      console.log("GET call failed: ", JSON.parse(e.response.body));
    }
  }

  async function getSkills() {
    try {
      const restOperation = get({
        apiName: "PortfolioSiteAPI",
        path: "/api/skills",
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      console.log(response);

      return response;
    } catch (e) {
      console.log("GET call failed: ", JSON.parse(e.response.body));
    }
  }

  async function deleteSkill(skill_id) {
    try {
      const restOperation = del({
        apiName: "PortfolioSiteAPI",
        path: "/api/skills/" + skill_id,
      });

      const { body } = await restOperation.response;
      const response = await body.json();
      setCurrentSkills(response.data);
      return response;
    } catch (e) {
      console.log("GET call failed: ", JSON.parse(e.response.body));
    }
  }

  const handleSubmit = (event) => {
    if (false) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }

    event.preventDefault();

    let jsonbody = {
      priority: event.target[0].value,
      name: event.target[1].value,
      description: event.target[2].value,
    };

    console.log(jsonbody);

    createSkill(jsonbody).then((res) => setCurrentSkills(res.data));
  };

  return (
    <>
      <Container>
        <h4>Profile</h4>
        <h6>Email: {user.email}</h6>
        <Button onClick={signOutUser}>Sign out of admin</Button>
      </Container>
      <br></br>
      <Row>
        <Col>
          <Container>
            <h4>Skills</h4>
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-3" controlId="formChars">
                <Form.Label column sm={3}>
                  Skill Priority:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="string"
                    placeholder="Skill priority"
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formChars">
                <Form.Label column sm={3}>
                  Skill Name:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="string"
                    placeholder="Skill name"
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formNumber">
                <Form.Label column sm={3}>
                  Skill Description:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="string"
                    placeholder="Skill description"
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
                <Col sm={{ span: 10, offset: 1 }}>
                  <Button className="CustomPrimary" type="submit">
                    <i class="bi bi-arrow-clockwise"></i> Create New Skill
                  </Button>
                </Col>
              </Form.Group>
            </Form>
          </Container>
          <Container>
            {currentSkills.length === 0 && <p> No Items Found</p>}

            <ul className="list-group AdminList">
              {currentSkills.map((item, index) => (
                <li key={item.id.S} className="list-group-item AdminListItem">
                  Priority: {item.priority.N}
                  <br></br>
                  Name: {item.name.S}
                  <br></br>
                  Description:{item.description.S}
                  <Button
                    className="btn CustomDangerDelete"
                    onClick={() => deleteSkill(item.id.S)}
                  >
                    <i class="bi bi-trash3"></i>
                  </Button>
                </li>
              ))}
            </ul>
          </Container>
        </Col>
        <Col><h4>Something</h4></Col>
      </Row>
    </>
  );
}

export default withAuthenticator(AdminConsole, { hideSignUp: true });
