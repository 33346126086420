import Nav from "react-bootstrap/Nav";
import Transition from "../transitions/transition";
import "./Home.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <>
      <div className="HomeContainer">
        <div className="TypewriterContainer">
          <p className="TypewriterText">Jamie Sommerville</p>
        </div>
        <div className="introduction">
          <p>Programmer</p>
        </div>

        <div className="NavCards">
          <motion.div whileHover={{ scale: 1.5 }} whileTap={{ scale: 1.3 }}>
            <div className="NavCard">
              <Nav className="justify-content-center" activeKey="/">
                <Nav.Item>
                  <Nav.Link className="CustomLink" as={Link} to={"/about"}>
                    About Me
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Transition(Home);
