import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { motion } from "framer-motion";

import JSONPretty from "react-json-pretty";
import Transition from "../transitions/transition";
import "./Prettifier.css"

function Prettifier() {
    const [name, setName] = useState("");

    const copyToClipboard = () =>  navigator.clipboard.writeText(JSON.stringify(JSON.parse(name),null,2));

    const clearInput = () => {setName("")}

    return (
        <>
            <Form>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                >
                    <h3>JSON Input</h3>
                    <Form.Control
                        type="email"
                        as="textarea"
                        className="InputArea"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder=""
                    />
                </Form.Group>
            </Form>
            <h3>JSON Output</h3>
            <div className="OutputArea">
                <JSONPretty className="OutputBox" id="json-pretty" space="4" data={name} ></JSONPretty >
            </div>
            <motion.div
              className="ButtonHolder"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.3 }}
            >
            <Button className="btn CustomPrimary" onClick={copyToClipboard}><i class="bi bi-clipboard"></i> Copy text to clipboard</Button><br/>
            </motion.div>
            <motion.div
              className="ButtonHolder"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.3 }}
            >
            <Button className="btn CustomDanger" onClick={clearInput}><i class="bi bi-trash3"></i> Clear Input</Button>
            </motion.div>
        </>
    );
}

export default Transition(Prettifier);
