import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Skills from "../components/Skills";
import "./About.css";
import Transition from "../transitions/transition";
const About = () => {
  return (
    <Container className="AboutPageContainer" fluid={true}>
      <Row md={1}>
        <Col className="HeadshotContainer">
          <img
            className="Wrap Headshot"
            src={require("../assets/headshot.jpg")}
            alt="headshot"
          ></img>
          <h2>About Me</h2>
          <p>
            Hey there, I'm Jamie Sommerville, a 24-year-old programmer from
            Toronto, Canada, with over eight years of coding experience and
            three years of professional experience.
          </p>
          <p>
            I have a bachelor of science degree in computer science with a minor
            in geography from Wilfrid Laurier University. I am also currently in progress to be an AWS
            Certified Associate Developer.
          </p>
          <p>
            Python has been my go-to language from the very beginning, but I've
            also practiced various others, like JavaScript, HTML, CSS, SQL, and
            Node.js and more. My journey in the professional world began with
            Angular and Node.js, where I worked on both front-end and back-end
            projects. Over time, I gravitated towards Python for back-end
            development, especially in serverless environments.
          </p>
          <p>
            I'm always eager to learn and adapt to whatever challenges await me,
            and I'm committed to bringing fresh ideas and solutions to the table
            wherever I go.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Interests</h2>
          <h5>Professional</h5>
          <ul>
            <li>Python</li>
            <li>AWS</li>
            <li>Web Development</li>
            <li>Geographical Data</li>
          </ul>
          <h5>Personal</h5>
          <ul>
            <li>Cycling</li>
            <li>Wood Working</li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2>Skills</h2>
          <Skills></Skills>
        </Col>
      </Row>
    </Container>
  );
};

export default Transition(About);
